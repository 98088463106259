import React, { useEffect, useState, useRef } from 'react'
import './ImageLoader.scss'
import { ImageLoader } from './index'
import { useControls, useTransformEffect } from 'react-zoom-pan-pinch'

const Zoom = ({ className, src, setShowLoading, activeImage, index, setIsImageZoomed, onFastSwipeLeft, onFastSwipeRight }) => {
  const [scale, setScale] = useState(1)
  const [isPanning, setIsPanning] = useState()
  const fastSwipeTriggered = useRef(false)

  const { resetTransform, zoomIn, zoomOut } = useControls()

  useEffect(() => {
    if (scale > 1 && index !== activeImage) {
      resetTransform()
    }
    return () => {
      resetTransform()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeImage])

  useTransformEffect(({ state, instance }) => {

    if (Math.abs(instance.velocity?.velocityX) > 30 &&
        !fastSwipeTriggered.current &&
        Math.abs(instance.velocity?.velocityY) < 50) {
      fastSwipeTriggered.current = true
      if (instance.velocity?.velocityX > 0) {
        onFastSwipeRight && onFastSwipeRight()
      } else {
        onFastSwipeLeft && onFastSwipeLeft()
      }
    }
    if (Math.abs(instance.velocity?.velocityX) < 30) {
      fastSwipeTriggered.current = false
    }

    setIsImageZoomed(state.scale > 1)
    setScale(state.scale)
    setIsPanning(instance.isPanning)
  })

  const clickZoomHandler = () => {
    if (!isPanning) {
      scale > 1 ? zoomOut() : zoomIn()
    }
  }

  const getPinchPanClasses = () => {
    if (isPanning) return 'panning'
    return scale > 1 ? 'zoomOut' : 'zoomIn'
  }

  return (
    <ImageLoader
      onMouseLeave={() => resetTransform()}
      onClick={() => clickZoomHandler()}
      className={`${className || ''} ${getPinchPanClasses()}`}
      setShowLoading={setShowLoading}
      src={src}
      activeImage={activeImage}
    />
  )
}

export default Zoom
